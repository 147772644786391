// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-gallery-js": () => import("./../../../src/templates/gallery.js" /* webpackChunkName: "component---src-templates-gallery-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-manufactory-js": () => import("./../../../src/templates/manufactory.js" /* webpackChunkName: "component---src-templates-manufactory-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-tvn-before-after-js": () => import("./../../../src/templates/tvn-before-after.js" /* webpackChunkName: "component---src-templates-tvn-before-after-js" */),
  "component---src-templates-tvn-js": () => import("./../../../src/templates/tvn.js" /* webpackChunkName: "component---src-templates-tvn-js" */),
  "component---src-templates-tvn-photos-js": () => import("./../../../src/templates/tvn-photos.js" /* webpackChunkName: "component---src-templates-tvn-photos-js" */)
}

